import { gql } from "@apollo/client";
import { createSlice } from "@reduxjs/toolkit";
import { createApi } from "@reduxjs/toolkit/query/react";
import { graphqlRequestBaseQuery } from "@rtk-query/graphql-request-base-query";
import { serverAuth } from "app/utils/server";
import { IDLE, LOADING, NOTFOUND, SUCCESS } from "app/utils/status";

const initialState = {
  form: undefined,
  forms: [],

  formStatus: IDLE,

  take: 10,
  page: 1,
  where: undefined,
  skip: undefined,
  orderBy: undefined,
};

export const formsSlice = createSlice({
  name: "forms",
  initialState,
  reducers: {
    setForm: (state, { payload }) => {
      state.form = payload;
    },
    setFormStatus: (state, { payload }) => {
      state.formStatus = payload;
    },
    setForms: (state, { payload }) => {
      state.forms = payload;
    },
    setWhere: (state, { payload }) => {
      state.where = payload;
    },
    setPage: (state, { payload }) => {
      state.page = payload;
      state.skip = (state.page - 1) * state.take;
    },
  },
});

export const formsApi = createApi({
  reducerPath: "formAPI",
  baseQuery: graphqlRequestBaseQuery(serverAuth),
  endpoints: (builder) => ({
    getForm: builder.query({
      query: ({ id, project }) => ({
        document: gql`
          query form($id: String!, $project: String!) {
            form(id: $id, project: $project) {
              id
              translations {
                id
                title
                email
                enabled
                structure
                language {
                  slug
                }
              }
            }
          }
        `,
        variables: {
          id,
          project,
        },
      }),
      transformResponse: (response) => response.form,
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(formsSlice.actions.setForm(data));
          let status = LOADING;
          if (data.id && data.translations.find((e) => e.language.slug === params.language)) status = SUCCESS;
          else status = NOTFOUND;
          dispatch(formsSlice.actions.setFormStatus(status));
        } catch (err) {
          dispatch(formsSlice.actions.setFormStatus(NOTFOUND));
          console.error("err", err);
        }
      },
    }),
    getForms: builder.query({
      query: ({ take, skip, where, orderBy }) => ({
        document: gql`
          query forms($take: Int, $skip: Int, $where: FormWhere, $orderBy: FormOrderBy) {
            forms(take: $take, skip: $skip, where: $where, orderBy: $orderBy) {
              id
              translations {
                id
                count
                title
                enabled
                structure
                unReadCount
                language {
                  slug
                }
              }
            }
          }
        `,
        variables: {
          take,
          skip,
          where,
          orderBy,
        },
      }),
      transformResponse: (response) => response.forms,
    }),
    getFormsTotal: builder.query({
      query: ({ where }) => ({
        document: gql`
          query formsTotal($where: FormWhere) {
            formsTotal(where: $where)
          }
        `,
        variables: {
          where,
        },
      }),
      transformResponse: (response) => response.formsTotal,
    }),
    getSubmissions: builder.query({
      query: ({ take, skip, where, orderBy }) => ({
        document: gql`
          query formSubmissions($take: Int, $skip: Int, $where: FormSubmissionWhere, $orderBy: FormSubmissionOrderBy) {
            formSubmissions(take: $take, skip: $skip, where: $where, orderBy: $orderBy) {
              id
              read
              values
              createdAt
            }
          }
        `,
        variables: {
          take,
          skip,
          where,
          orderBy,
        },
      }),
      transformResponse: (response) => response.formSubmissions,
    }),
    createForm: builder.mutation({
      query: ({ title, language, project }) => ({
        document: gql`
          mutation createForm($title: String!, $language: GenericConnect!, $project: GenericConnect!) {
            createForm(title: $title, language: $language, project: $project) {
              id
              translations {
                id
                title
              }
            }
          }
        `,
        variables: {
          title,
          language: { connect: { id: language } },
          project: { connect: { id: project } },
        },
      }),
      transformResponse: (response) => response.createForm,
    }),
    createFormTranslation: builder.mutation({
      query: ({ form, language, title, copy, origin, translate }) => ({
        document: gql`
          mutation createFormTranslation($title: String!, $language: GenericConnect!, $form: GenericConnect!, $copy: Boolean!, $origin: ID, $translate: Boolean!) {
            createFormTranslation(title: $title, language: $language, form: $form, copy: $copy, origin: $origin, translate: $translate) {
              id
            }
          }
        `,
        variables: {
          title,
          form: { connect: { id: form } },
          language: { connect: { id: language } },
          copy: copy || false,
          origin: origin || undefined,
          translate: translate || false,
        },
      }),
      transformResponse: (response) => response.createFormTranslation,
    }),
    updateForm: builder.mutation({
      query: ({ id, title, published, recipients, emails }) => ({
        document: gql`
          mutation updateFormTranslation($id: String!, $title: String, $enabled: Boolean, $recipients: [String!], $emails: Json) {
            updateFormTranslation(id: $id, title: $title, enabled: $enabled, recipients: $recipients, emails: $emails) {
              id
              translations {
                id
                title
                email
                enabled
                structure
                language {
                  slug
                }
              }
            }
          }
        `,
        variables: {
          id,
          title,
          emails,
          enabled: published,
          recipients,
        },
      }),
      transformResponse: (response) => response.updateFormTranslation,
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(formsSlice.actions.setForm(data));
          dispatch(formsSlice.actions.setFormStatus(SUCCESS));
        } catch (err) {
          dispatch(formsSlice.actions.setFormStatus(NOTFOUND));
          console.error("err", err);
        }
      },
    }),
    updateFormStructure: builder.mutation({
      query: ({ id, structure }) => ({
        document: gql`
          mutation updateFormTranslation($id: String!, $structure: Json) {
            updateFormTranslation(id: $id, structure: $structure) {
              id
              translations {
                id
                title
                email
                enabled
                structure
                language {
                  slug
                }
              }
            }
          }
        `,
        variables: {
          id,
          structure,
        },
      }),
      transformResponse: (response) => response.updateFormTranslation,
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(formsSlice.actions.setForm(data));
          dispatch(formsSlice.actions.setFormStatus(SUCCESS));
        } catch (err) {
          dispatch(formsSlice.actions.setFormStatus(NOTFOUND));
          console.error("err", err);
        }
      },
    }),
    duplicateForm: builder.mutation({
      query: ({ form, language, project }) => ({
        document: gql`
          mutation duplicateForm($form: String!, $project: GenericConnect!, $language: GenericConnect!) {
            duplicateForm(form: $form, project: $project, language: $language) {
              id
              translations {
                id
                title
              }
            }
          }
        `,
        variables: {
          form,
          project: { connect: { id: project } },
          language: { connect: { id: language } },
        },
      }),
      transformResponse: (response) => response.duplicateForm,
    }),
    deleteForm: builder.mutation({
      query: ({ id, project, language, complete }) => ({
        document: gql`
          mutation deleteForm($id: String!, $project: String!, $language: String!, $complete: Boolean!) {
            deleteForm(id: $id, project: $project, language: $language, complete: $complete) {
              id
            }
          }
        `,
        variables: {
          id,
          project,
          language,
          complete,
        },
      }),
      transformResponse: (response) => response.deleteForm,
    }),
    markAsRead: builder.mutation({
      query: ({ id, read }) => ({
        document: gql`
          mutation updateFormSubmission($id: String!, $read: Boolean!) {
            updateFormSubmission(id: $id, read: $read) {
              id
              read
            }
          }
        `,
        variables: {
          id,
          read,
        },
      }),
      transformResponse: (response) => response.updateFormSubmission,
    }),
    deleteFormSubmission: builder.mutation({
      query: ({ id, project }) => ({
        document: gql`
          mutation deleteFormSubmission($id: String!, $project: String!) {
            deleteFormSubmission(id: $id, project: $project) {
              id
            }
          }
        `,
        variables: {
          id,
          project,
        },
      }),
      transformResponse: (response) => response.deleteFormSubmission,
    }),
  }),
});

export const { setForms, setWhere, setPage } = formsSlice.actions;

export const {
  useGetFormsQuery,
  useGetFormsTotalQuery,
  useGetFormQuery,
  useGetSubmissionsQuery,
  useCreateFormMutation,
  useCreateFormTranslationMutation,
  useDuplicateFormMutation,
  useDeleteFormMutation,
  useUpdateFormMutation,
  useUpdateFormStructureMutation,
  useMarkAsReadMutation,
  useDeleteFormSubmissionMutation,
} = formsApi;

export default formsSlice.reducer;
